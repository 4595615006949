var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "img-uplaod-container", attrs: { id: _vm.uploadId } },
    [
      _c(
        "draggable",
        _vm._b(
          {
            staticClass: "drag-img-list",
            attrs: { "no-transition-on-drag": true },
            model: {
              value: _vm.fileList,
              callback: function ($$v) {
                _vm.fileList = $$v
              },
              expression: "fileList",
            },
          },
          "draggable",
          { group: _vm.uploadId, ghostClass: "ghost", animation: 200 },
          false
        ),
        _vm._l(_vm.fileList, function (item) {
          return _c(
            "div",
            {
              key: item.key,
              staticClass: "upload-file",
              class: {
                uploading: item.status == "uploading",
                "is-success": item.status == "success",
                "is-diabled": _vm.disabled,
              },
              style: { width: _vm.width + "px", height: _vm.height + "px" },
              attrs: { id: item.key },
            },
            [
              item.isImg
                ? _c("img", { attrs: { src: item.url } })
                : _c("i", {
                    staticClass: "el-icon-files",
                    staticStyle: { "font-size": "40px", color: "#4ca1ff" },
                    attrs: { title: "文件" },
                  }),
              item.status == "uploading"
                ? _c("el-progress", {
                    staticClass: "upload-progress",
                    attrs: {
                      width: _vm.miniWidth * 0.9,
                      type: "circle",
                      percentage: item.percent,
                    },
                  })
                : _vm._e(),
              item.status == "success"
                ? _c("label", { staticClass: "item-status" }, [
                    _c("i", {
                      staticClass: "el-icon-upload-success el-icon-check",
                    }),
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "uplaod-action",
                  style: { height: _vm.miniWidth / 4 + "px" },
                },
                [
                  _c("i", {
                    class: item.isImg ? "el-icon-view" : "el-icon-download",
                    style: { "font-size": _vm.miniWidth / 8 + "px" },
                    attrs: { title: item.isImg ? "预览" : "下载" },
                    on: {
                      click: function ($event) {
                        return _vm.handlePreviewFile(item)
                      },
                    },
                  }),
                  _vm.isEdit && !_vm.disabled
                    ? _c("i", {
                        staticClass: "el-icon-refresh",
                        style: { "font-size": _vm.miniWidth / 8 + "px" },
                        attrs: { title: "替换" },
                        on: {
                          click: function ($event) {
                            return _vm.handleEdit(item.key)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm.isDelete && _vm.fileList.length > _vm.min && !_vm.disabled
                    ? _c("i", {
                        staticClass: "el-icon-delete",
                        style: { "font-size": _vm.miniWidth / 8 + "px" },
                        attrs: { title: "删除" },
                        on: {
                          click: function ($event) {
                            return _vm.handleRemove(item.key)
                          },
                        },
                      })
                    : _vm._e(),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (!_vm.isQiniu || (_vm.isQiniu && _vm.token)) &&
                _vm.fileList.length < _vm.length,
              expression:
                "(!isQiniu || (isQiniu && token)) && fileList.length < length",
            },
          ],
          staticClass: "el-upload el-upload--picture-card",
          class: { "is-disabled": _vm.disabled },
          style: { width: _vm.width + "px", height: _vm.height + "px" },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.handleAdd.apply(null, arguments)
            },
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-plus",
            style: {
              fontSize: _vm.miniWidth / 4 + "px",
              marginTop: -_vm.miniWidth / 8 + "px",
              marginLeft: -_vm.miniWidth / 8 + "px",
            },
            on: {
              click: function ($event) {
                if ($event.target !== $event.currentTarget) return null
                return _vm.handleAdd.apply(null, arguments)
              },
            },
          }),
          _vm.multiple
            ? _c("input", {
                ref: "uploadInput",
                staticClass: "el-upload__input upload-input",
                style: { width: 0, height: 0 },
                attrs: { multiple: "", type: "file", name: "files" },
                on: { change: _vm.handleChange },
              })
            : _c("input", {
                ref: "uploadInput",
                staticClass: "el-upload__input upload-input",
                style: { width: 0, height: 0 },
                attrs: { type: "file", name: "files" },
                on: { change: _vm.handleChange },
              }),
        ]
      ),
      _c(
        "Model",
        {
          ref: "formPreview",
          attrs: { visible: _vm.previewVisible, width: "600px", form: "" },
          on: {
            "on-close": function ($event) {
              _vm.previewVisible = false
            },
          },
        },
        [
          _c("img", {
            staticStyle: { display: "block", width: "80%", margin: "0 auto" },
            attrs: { src: _vm.previewUrl, alt: "" },
          }),
          _c(
            "template",
            { slot: "action" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "mini" },
                  on: {
                    click: function ($event) {
                      _vm.previewVisible = false
                    },
                  },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }