var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-form-item",
    { attrs: { label: _vm.formData.name, prop: _vm.formData.model } },
    [
      _vm.formData.type == "input"
        ? [
            _vm.formData.options.dataType == "number" ||
            _vm.formData.options.dataType == "integer" ||
            _vm.formData.options.dataType == "float"
              ? _c("el-input", {
                  style: { width: _vm.formData.options.width },
                  attrs: {
                    type: _vm.formData.options.dataType,
                    placeholder: _vm.formData.options.placeholder,
                    disabled: _vm.formData.options.disabled,
                  },
                  model: {
                    value: _vm.dataModel,
                    callback: function ($$v) {
                      _vm.dataModel = _vm._n($$v)
                    },
                    expression: "dataModel",
                  },
                })
              : _c("el-input", {
                  style: { width: _vm.formData.options.width },
                  attrs: {
                    type: _vm.formData.options.dataType,
                    disabled: _vm.formData.options.disabled,
                    placeholder: _vm.formData.options.placeholder,
                  },
                  model: {
                    value: _vm.dataModel,
                    callback: function ($$v) {
                      _vm.dataModel = $$v
                    },
                    expression: "dataModel",
                  },
                }),
          ]
        : _vm._e(),
      _vm.formData.type == "textarea"
        ? [
            _c("el-input", {
              style: { width: _vm.formData.options.width },
              attrs: {
                type: "textarea",
                rows: 5,
                disabled: _vm.formData.options.disabled,
                placeholder: _vm.formData.options.placeholder,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "number"
        ? [
            _c("el-input-number", {
              style: { width: _vm.formData.options.width },
              attrs: {
                step: _vm.formData.options.step,
                min:
                  _vm.formData.options.min > 0
                    ? _vm.formData.options.min
                    : -Infinity,
                max:
                  _vm.formData.options.max > 0
                    ? _vm.formData.options.max
                    : Infinity,
                "controls-position": "right",
                disabled: _vm.formData.options.disabled,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "radio"
        ? [
            _c(
              "el-radio-group",
              {
                style: { width: _vm.formData.options.width },
                attrs: { disabled: _vm.formData.options.disabled },
                model: {
                  value: _vm.dataModel,
                  callback: function ($$v) {
                    _vm.dataModel = $$v
                  },
                  expression: "dataModel",
                },
              },
              _vm._l(
                _vm.formData.options.remote
                  ? _vm.formData.options.remoteOptions
                  : _vm.formData.options.options,
                function (item, index) {
                  return _c(
                    "el-radio",
                    {
                      key: index,
                      style: {
                        display: _vm.formData.options.inline
                          ? "inline-block"
                          : "block",
                      },
                      attrs: { label: item.value },
                    },
                    [
                      _vm.formData.options.remote
                        ? [_vm._v(_vm._s(item.label))]
                        : [
                            _vm._v(
                              _vm._s(
                                _vm.formData.options.showLabel
                                  ? item.label
                                  : item.value
                              )
                            ),
                          ],
                    ],
                    2
                  )
                }
              ),
              1
            ),
          ]
        : _vm._e(),
      _vm.formData.type == "checkbox"
        ? [
            _c(
              "el-checkbox-group",
              {
                style: { width: _vm.formData.options.width },
                attrs: { disabled: _vm.formData.options.disabled },
                model: {
                  value: _vm.dataModel,
                  callback: function ($$v) {
                    _vm.dataModel = $$v
                  },
                  expression: "dataModel",
                },
              },
              _vm._l(
                _vm.formData.options.remote
                  ? _vm.formData.options.remoteOptions
                  : _vm.formData.options.options,
                function (item, index) {
                  return _c(
                    "el-checkbox",
                    {
                      key: index,
                      style: {
                        display: _vm.formData.options.inline
                          ? "inline-block"
                          : "block",
                      },
                      attrs: { label: item.value },
                    },
                    [
                      _vm.formData.options.remote
                        ? [_vm._v(_vm._s(item.label))]
                        : [
                            _vm._v(
                              _vm._s(
                                _vm.formData.options.showLabel
                                  ? item.label
                                  : item.value
                              )
                            ),
                          ],
                    ],
                    2
                  )
                }
              ),
              1
            ),
          ]
        : _vm._e(),
      _vm.formData.type == "time"
        ? [
            _c("el-time-picker", {
              style: { width: _vm.formData.options.width },
              attrs: {
                "is-range": _vm.formData.options.isRange,
                placeholder: _vm.formData.options.placeholder,
                "start-placeholder": _vm.formData.options.startPlaceholder,
                "end-placeholder": _vm.formData.options.endPlaceholder,
                readonly: _vm.formData.options.readonly,
                disabled: _vm.formData.options.disabled,
                editable: _vm.formData.options.editable,
                clearable: _vm.formData.options.clearable,
                arrowControl: _vm.formData.options.arrowControl,
                "value-format": _vm.formData.options.format,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "date"
        ? [
            _c("el-date-picker", {
              style: { width: _vm.formData.options.width },
              attrs: {
                type: _vm.formData.options.type,
                placeholder: _vm.formData.options.placeholder,
                "start-placeholder": _vm.formData.options.startPlaceholder,
                "end-placeholder": _vm.formData.options.endPlaceholder,
                readonly: _vm.formData.options.readonly,
                disabled: _vm.formData.options.disabled,
                editable: _vm.formData.options.editable,
                clearable: _vm.formData.options.clearable,
                "value-format": _vm.formData.options.timestamp
                  ? "timestamp"
                  : _vm.formData.options.format,
                format: _vm.formData.options.format,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "rate"
        ? [
            _c("el-rate", {
              attrs: {
                max: _vm.formData.options.max,
                disabled: _vm.formData.options.disabled,
                "allow-half": _vm.formData.options.allowHalf,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "color"
        ? [
            _c("el-color-picker", {
              attrs: {
                disabled: _vm.formData.options.disabled,
                "show-alpha": _vm.formData.options.showAlpha,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "select"
        ? [
            _c(
              "el-select",
              {
                style: { width: _vm.formData.options.width },
                attrs: {
                  disabled: _vm.formData.options.disabled,
                  multiple: _vm.formData.options.multiple,
                  clearable: _vm.formData.options.clearable,
                  placeholder: _vm.formData.options.placeholder,
                  filterable: _vm.formData.options.filterable,
                },
                model: {
                  value: _vm.dataModel,
                  callback: function ($$v) {
                    _vm.dataModel = $$v
                  },
                  expression: "dataModel",
                },
              },
              _vm._l(
                _vm.formData.options.remote
                  ? _vm.formData.options.remoteOptions
                  : _vm.formData.options.options,
                function (item) {
                  return _c("el-option", {
                    key: item.value,
                    attrs: {
                      value: item.value,
                      label:
                        _vm.formData.options.showLabel ||
                        _vm.formData.options.remote
                          ? item.label
                          : item.value,
                    },
                  })
                }
              ),
              1
            ),
          ]
        : _vm._e(),
      _vm.formData.type == "switch"
        ? [
            _c("el-switch", {
              attrs: { disabled: _vm.formData.options.disabled },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "slider"
        ? [
            _c("el-slider", {
              style: { width: _vm.formData.options.width },
              attrs: {
                min: _vm.formData.options.min,
                max: _vm.formData.options.max,
                disabled: _vm.formData.options.disabled,
                step: _vm.formData.options.step,
                "show-input": _vm.formData.options.showInput,
                range: _vm.formData.options.range,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type === "imgupload"
        ? [
            _c("Upload", {
              style: { width: _vm.formData.options.width },
              attrs: {
                disabled: _vm.formData.options.disabled,
                width: _vm.formData.options.size.width,
                height: _vm.formData.options.size.height,
                token: _vm.formData.options.token,
                domain: _vm.formData.options.domain,
                multiple: _vm.formData.options.multiple,
                length: _vm.formData.options.length,
                "is-qiniu": _vm.formData.options.isQiniu,
                "is-delete": _vm.formData.options.isDelete,
                min: _vm.formData.options.min,
                "is-edit": _vm.formData.options.isEdit,
                action: _vm.formData.options.action,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "editor"
        ? [
            _c("vue-editor", {
              style: { width: _vm.formData.options.width },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "cascader"
        ? [
            _c("el-cascader", {
              style: { width: _vm.formData.options.width },
              attrs: {
                disabled: _vm.formData.options.disabled,
                clearable: _vm.formData.options.clearable,
                placeholder: _vm.formData.options.placeholder,
                options: _vm.formData.options.remoteOptions,
              },
              model: {
                value: _vm.dataModel,
                callback: function ($$v) {
                  _vm.dataModel = $$v
                },
                expression: "dataModel",
              },
            }),
          ]
        : _vm._e(),
      _vm.formData.type == "text"
        ? [_c("span", [_vm._v(_vm._s(_vm.dataModel))])]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }