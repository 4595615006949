var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      ref: "elDialog",
      staticClass: "cus-dialog-container",
      attrs: {
        title: _vm.title,
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
        "append-to-body": "",
        center: "",
        width: _vm.width,
        id: _vm.id,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _vm.show ? _c("span", [_vm._t("default")], 2) : _vm._e(),
      _vm.action
        ? _c(
            "span",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticClass: "dialog-footer",
              attrs: {
                slot: "footer",
                "element-loading-text": _vm.loadingText,
              },
              slot: "footer",
            },
            [
              _vm._t("action", function () {
                return [
                  _c("el-button", { on: { click: _vm.close } }, [
                    _vm._v("取消"),
                  ]),
                  _c(
                    "el-button",
                    { attrs: { type: "primary" }, on: { click: _vm.submit } },
                    [_vm._v("确定")]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }